<template>
  <div>
    <canvas ref="myChart" class="canvas"></canvas>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Chart from "chart.js/auto";
const apiUrl = process.env.VUE_APP_API_URL;

export default {
  data() {
    return {
      dataset: [],
    };
  },

  methods: {
    renderChart() {
      const ctx = this.$refs.myChart;

      const currentDate = moment();
      const labels = Array.from({ length: 12 }, (_, i) =>
        currentDate.clone().subtract(i, "months").format("MMMM YYYY")
      ).reverse();

      new Chart(ctx, {
        type: "line",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Used value in dolar",
              data: this.dataset,
              borderWidth: 1,
              borderColor: "rgba(172, 209, 175)",
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              grid: {
                color: "rgba(128, 128, 128, 0.2)",
              },
            },
            x: {
              beginAtZero: true,
              grid: {
                color: "rgba(128, 128, 128, 0.2)",
              },
            },
          },
        },
      });
    },

    getDataSet() {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken !== null) {
        axios
          .get(`${apiUrl}/api/get/dataset/${accessToken}`, {
            withCredentials: true,
          })
          .then((response) => {
            if (response.data.length != 0) {
              this.dataset = Object.values(response.data).sort((a, b) => {
                const dateA = new Date(a);
                const dateB = new Date(b);
                return dateA - dateB;
              });
              this.dataset = this.dataset.map((value) => value / 250);
            }
            this.renderChart();
          })

          .catch((error) => {
            try {
              console.log(error.response.data.error);
            } catch (err) {
              console.log(err);
            }
          });
      }
    },
  },

  mounted() {
    this.getDataSet();
  },
};
</script>

<style>
@media screen and (min-width: 320px) and (max-width: 1004px) {
  .canvas {
    max-width: 500px;
  }
}
</style>
