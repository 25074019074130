<template>
  <div class="header">
    <img
      src="../assets/icon.png"
      alt="chartwo"
      class="logo"
      @click="handleClick"
    />
    <div class="links">
      <div class="contentLinks">
        <a class="aboutContentUp" @click="exportImages">
          <i class="fa fa-share-alt share" aria-hidden="true"></i>
        </a>
      </div>
      <div class="contentLinks">
        <a class="aboutContentUp" @click="logout">
          <i class="fas fa-sign-out share" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </div>
  <div>
    <!-- <BackgroundCompVue /> -->
    <div
      :style="{
        width: 'auto',
        height: 'auto',
        position: 'fixed',
        top: '0',
        left: '0',
        zIndex: '-999',
        width: '100%',
        height: '100%',
        background: '#373737',
      }"
    ></div>
    <div class="leftSidebar">
      <div
        v-for="(imagem, index) in imagens"
        :key="index"
        class="imagem-container"
        @mousemove="handleMouseMove"
      >
        <div
          :style="{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
          }"
        >
          <img
            :src="getBase64ImageSrc(imagem, index)"
            alt="ImageRes"
            :class="{ ImageRes: !imageLoaded }"
            @load="handleImageLoad"
            :style="{
              width: '60px',
              height: '60px',
              borderRadius: '10px',
              border: '1px solid #fefefe',
              marginBottom: '5px',
            }"
          />
          <p
            :style="{
              color: '#fefefe',
            }"
          >
            {{ time[index] }}
          </p>
        </div>
      </div>
    </div>

    <div class="containerWork">
      <div :class="{ controllerMargin: imageUrl, scrollImages: !imageUrl }">
        <div class="scrollContent" ref="scrollContent">
          <div
            v-for="(imagem, index) in imagens"
            :key="index"
            class="imagem-container"
            @mousemove="handleMouseMove"
          >
            <img
              :src="getBase64ImageSrc(imagem, index)"
              alt="ImageRes"
              :class="{ ImageRes: !imageLoaded }"
              @load="handleImageLoad"
              class="ImageResStyle"
            />
            <div class="questContainer" v-if="!imagem.isHidden">
              <div>Is this image useful?</div>
              <button @click="() => resImage(imagem, true, index)">Yes</button>
              <button @click="() => resImage(imagem, false, index)">No</button>
            </div>
          </div>

          <div v-if="!imageLoaded" class="loader">Upload image</div>
          <div v-if="loading">
            <LoadingComp />
          </div>
        </div>
      </div>
      <div class="container2work">
        <div>
          <div class="Inputs">
            <label for="fileInput" class="custom-file-upload">
              Select file
              <div v-if="imageUrl" class="image-preview">
                <img :src="imageUrl" alt="Preview" />
              </div>
            </label>
            <label for="PorCentInput" class="PorCentLabel">
              <input
                type="number"
                class="PorCentInput"
                id="numero"
                v-model="numero"
                min="0"
                max="100"
                placeholder="%"
              />
            </label>
          </div>
          <input
            type="file"
            id="fileInput"
            ref="fileInput"
            @change="previewImage"
            accept="image/*"
          />
          <div v-if="imageUrl" class="image-preview">
            <button class="custom-btn-upload" @click="submit(imageUrl)">
              <div>Submit</div>
              <i class="fas fa-paper-plane"></i>
            </button>
          </div>
        </div>
        <FooterWorkCompVue />
      </div>
    </div>
  </div>
</template>

<script>
import FooterWorkCompVue from "@/components/FooterWorkComp.vue";
// import BackgroundCompVue from "@/components/BackgroundComp.vue";
const apiUrl = process.env.VUE_APP_API_URL;
import router from "@/routes/Router";
import axios from "axios";
import LoadingComp from "@/components/LoadingComp.vue";

export default {
  data() {
    return {
      imageUrl: null,
      imageUrlResponse: null,
      imageLoaded: false,
      imagens: [],
      time: [],
      numero: 100,
      loading: false,
    };
  },

  mounted() {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      alert("You need be loged to access the page.");
      window.location.href = "#/";
    }
  },

  components: {
    FooterWorkCompVue,
    //BackgroundCompVue,
    LoadingComp,
  },

  methods: {
    scrollDown() {
      setTimeout(() => {
        // Acessa o elemento de scroll usando a ref
        const container = this.$refs.scrollContent;

        // Faz o scroll para o final do container
        container.scrollTop = container.scrollHeight;
      }, 1000);
    },

    previewImage() {
      const input = this.$refs.fileInput;
      const files = input.files;

      if (files && files[0]) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.imageUrl = e.target.result;
        };

        reader.readAsDataURL(files[0]);
      }
    },

    exportImages() {
      const imagens = this.imagens;

      axios
        .post(
          `${apiUrl}/api/export/images`,
          {
            userId: localStorage.getItem("accessToken"),
            imagens: imagens,
          },
          {
            withCredentials: true,
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "imagens.zip");
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          alert(`${error.response.data.erro}`);
        });
    },

    handleClick() {
      router.push("/");
    },

    saveChart(chartImg, isUseful) {
      axios
        .post(
          `${apiUrl}/api/save/charts`,
          {
            userId: localStorage.getItem("accessToken"),
            chartImg: chartImg,
            isUseful: isUseful,
          },
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          if (!localStorage.getItem("firstImageReview")) {
            alert(
              "By saying if the image is useful you can compete for 1 year of the next completely free Chartwo sentinel model, keep voting to increase your chances."
            );
          }
          localStorage.setItem("firstImageReview", true);
          console.log(response);
        })
        .catch((error) => {
          try {
            alert(`${error.response.data.erro}`);
          } catch (error) {
            alert(`Please check your connection.`);
          }
        });
    },

    resImage(chartImg, isUseful, index) {
      this.imagens[index] = { img: chartImg, isHidden: true };

      if (isUseful) {
        this.saveChart(chartImg, isUseful);
      } else {
        this.saveChart(chartImg, isUseful);
      }
    },

    handleImageLoad() {
      this.imageLoaded = true;
    },

    submit() {
      const parts = this.imageUrl.split(",");
      const base64string = parts[1];

      if (this.numero > 100 || this.numero < 0) {
        alert("Choose a number from 1 to 100.");
      } else {
        this.loading = true;

        this.scrollDown();

        axios
          .post(
            `${apiUrl}/api/messages`,
            {
              userId: localStorage.getItem("accessToken"),
              imageUrl: base64string,
              percent: this.numero,
            },
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            this.loading = false;
            // alert(response.data.message);
            this.imagens.push(response.data.img);

            const currentTime = new Date().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });
            this.time.push(currentTime);

            this.imageUrlResponse = response.data.img;
            // console.log(response);

            this.scrollDown();
          })
          .catch((error) => {
            this.loading = false;
            try {
              alert(`${error.response.data.erro}`);
            } catch (error) {
              alert(`Please check your connection.`);
            }

            // console.log(error.response.data.erro);
          });
      }
    },

    getBase64ImageSrc(base64) {
      try {
        if (typeof base64 === "string") {
          return `data:image/png;base64,${base64}`;
        } else if (base64 && base64.img) {
          return `data:image/png;base64,${base64.img}`;
        } else {
          console.error("Invalid base64 format:", base64);
          return "";
        }
      } catch (error) {
        console.error("Error converting Base64 image:", error);
        return "";
      }
    },

    logout() {
      var res = confirm("Do you want to log out?");

      if (res) localStorage.removeItem("accessToken");
    },
  },

  computed: {
    imageUrlRes() {
      return `data:image/jpeg;base64,${this.imageUrlResponse}`;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import "~@fortawesome/fontawesome-free/css/all.min.css";

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
}

.ImageRes {
  display: none;
}

.scrollImages {
  width: calc(60vw - 33px);
  border: 1px solid #3f3f3f;
  border-radius: 4px;
  padding: 10px 15px;
  overflow: hidden;
  height: 70vh;
  margin-bottom: 145px;
}

.controllerMargin {
  width: 55vw;
  border: 1px solid #3f3f3f;
  border-radius: 4px;
  padding: 10px 15px;
  overflow: hidden;
  height: 60vh;
  margin-bottom: 230px;
}

.scrollContent {
  overflow-y: scroll;
  height: 100%;
  padding: 0px 15px 0 15px;
  display: grid;
  place-items: center;
  justify-content: center;
  align-items: center;

  scrollbar-color: #4a4a4a #282828;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #4a4a4a;
  }

  &::-webkit-scrollbar-track {
    background-color: #282828;
  }

  scroll-behavior: smooth;
}

.loader {
  color: #3f3f3f;
  font-weight: bolder;
}

.ImageResStyle {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: 25px 0 25px 0;
  transition: transform 0.5s;
}

.image-preview img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.custom-file-upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  background-color: transparent;
  color: #fff;
  border-radius: 4px;
  border: 1px solid #3f3f3f;
  font-weight: bolder;
  font-size: 14px;
  width: 55vw;
}

.custom-btn-upload {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  cursor: pointer;
  background-color: transparent;
  color: #fff;
  border-radius: 4px;
  border: 1px solid transparent;
  font-weight: bolder;
  font-size: 14px;
  width: 100%;
  margin-top: 10px;
  transition: all 300ms ease-out;
}

.custom-btn-upload:hover {
  background-color: #3f3f3f88;
  transition: all 300ms ease-out;
  border: 1px solid #3f3f3f;
}

.Inputs {
  display: flex;
  justify-content: center;
  width: 60vw;
}

.PorCentInput {
  border: 1px solid #3f3f3f;
  background-color: transparent;
  border-radius: 4px;
  color: #fefefe;
  font-weight: bolder;
  height: calc(100% - 5px);
  margin-left: 15px;
}

#fileInput {
  display: none;
}

.containerWork {
  display: flex;
  padding: 50px;
  align-items: center;
  justify-content: center;
  background-color: #00000088;
  height: calc(100vh - 180px);
}

.questContainer {
  display: flex;
}

.questContainer div {
  color: gray;
  font-weight: bolder;
}

.questContainer button {
  margin-left: 15px;
  color: gray;
  background-color: transparent;
  border: 1px solid gray;
  font-weight: bolder;
  cursor: pointer;
  transition: all 300ms ease;
  border-radius: 5px;
  width: 45px;
}

.questContainer button:hover {
  color: black;
  background-color: gray;
  cursor: pointer;
  transition: all 300ms ease;
}

.container2work {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  position: fixed;
  z-index: 30;
  bottom: 0;
  margin-bottom: 10px;
  color: #fefefe;
}

.content2work {
  padding: 15px;
  text-align: center;
  text-decoration: none;
  color: #fefefe;
  margin-top: 5px;
}

.content2:hover {
  text-decoration: underline;
  cursor: pointer;
}

.line {
  border-bottom: 1px solid #fefefe;
  width: 100%;
}

.miniconsWork {
  color: #fefefe;
  padding: 15px;
  font-size: 14px;
  margin: 0 15px 0 15px;
}

.container3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftSidebar {
  width: auto;
  height: auto;
  position: fixed;
  left: 0;
  width: 15vw;
  height: calc(100% - 90px);
  background: #131313;
  padding: 5px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #4a4a4a;
  }

  &::-webkit-scrollbar-track {
    background-color: #282828;
  }

  scroll-behavior: smooth;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .content2 {
    font-size: 12px;
  }
  .ImageResStyle {
    max-width: 350px;
    height: auto;
    background-size: cover;
  }
  .scrollImages {
    width: calc(80vw - 33px);
    border: 1px solid #3f3f3f;
    border-radius: 4px;
    padding: 10px 15px;
    overflow: hidden;
    height: 65vh;
    margin-bottom: 155px;
  }

  .controllerMargin {
    width: calc(80vw - 33px);
    border: 1px solid #3f3f3f;
    border-radius: 4px;
    padding: 10px 15px;
    overflow: hidden;
    height: 55vh;
    margin-bottom: 240px;
  }

  .Inputs {
    width: 75vw;
  }
  .leftSidebar {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1204px) {
  .scrollImages {
    width: calc(60vw - 33px);
    border: 1px solid #3f3f3f;
    border-radius: 4px;
    padding: 10px 15px;
    overflow: hidden;
    height: 65vh;
    margin-bottom: 145px;
  }

  .controllerMargin {
    width: 55vw;
    border: 1px solid #3f3f3f;
    border-radius: 4px;
    padding: 10px 15px;
    overflow: hidden;
    height: 55vh;
    margin-bottom: 240px;
  }
}

@media screen and (min-width: 368px) and (max-width: 394px) {
  .scrollImages {
    height: 400px;
    width: calc(90vw);
  }
}

@media screen and (min-width: 280px) and (max-width: 294px) {
  .scrollImages {
    height: 360px;
    width: calc(45vw + 10px);
    margin-top: -25px;
  }
  .leftSidebar {
    display: none;
  }
}

@media screen and (min-width: 280px) and (max-width: 861px) {
  .leftSidebar {
    display: none;
  }
}
</style>
