<template>
  <div>
    <label class="contLabel">Your best card</label>
    <div class="checkoutCont">
      <StripeElements
        v-if="stripeLoaded && data.length === 0"
        v-slot="{ elements }"
        ref="elms"
        :stripe-key="stripeKey"
        :instance-options="instanceOptions"
        :elements-options="elementsOptions"
      >
        <StripeElement ref="card" :elements="elements" :options="cardOptions" />
      </StripeElements>
      <div v-if="data.length !== 0">
        <ul class="ul-card">
          <li v-for="(item, index) in data" :key="index" class="li-card">
            <div v-if="typeof item === 'object'">
              <div class="credit-card">
                <div class="card-brand">{{ item.brand }}</div>
                <div class="card-number">**** **** **** {{ item.last4 }}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <button
        type="button"
        @click="addCard"
        class="add"
        v-if="data.length === 0"
      >
        Add
      </button>
      <div v-if="data.length !== 0">
        <button type="button" @click="updateCard" class="add">
          Update Card
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { StripeElements, StripeElement } from "vue-stripe-js";
import { defineComponent, ref, onBeforeMount } from "vue";
import { loadStripe } from "@stripe/stripe-js";
const PUB_KEY = process.env.VUE_APP_PUB_KEY;
const apiUrl = process.env.VUE_APP_API_URL;
import axios from "axios";

export default defineComponent({
  name: "CardOnly",

  data() {
    return {
      data: [],
    };
  },

  components: {
    StripeElements,
    StripeElement,
  },

  setup() {
    const stripeKey = ref(PUB_KEY);
    const instanceOptions = ref({});
    const elementsOptions = ref({});
    const cardOptions = ref({
      value: {
        postalCode: "",
      },
      style: {
        base: {
          color: "#fefefe",
          "::placeholder": {
            color: "gray",
            fontWeight: "bolder",
          },
          fontSize: "18px",
        },
      },
    });
    const stripeLoaded = ref(false);
    const card = ref();
    const elms = ref();

    onBeforeMount(() => {
      const stripePromise = loadStripe(stripeKey.value);
      stripePromise.then(() => {
        stripeLoaded.value = true;
      });
    });

    return {
      stripeKey,
      stripeLoaded,
      instanceOptions,
      elementsOptions,
      cardOptions,
      card,
      elms,
    };
  },

  methods: {
    getCard(accessToken) {
      axios
        .get(`${apiUrl}/api/get/card/${accessToken}`, {
          withCredentials: true,
        })
        .then((response) => {
          if (response.data.length != 0) {
            this.data = response.data;
          }
        })
        .catch((error) => {
          try {
            console.log(error.response.data.error);
          } catch (err) {
            console.log(err);
          }
        });
    },

    addCard() {
      const cardElement = this.card.stripeElement;
      const accessToken = localStorage.getItem("accessToken");

      this.elms.instance.createToken(cardElement).then((result) => {
        axios
          .post(
            `${apiUrl}/api/add/card`,
            {
              userId: localStorage.getItem("accessToken"),
              result: result,
            },
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            alert(response.data.message);
            this.getCard(accessToken);
          })
          .catch((error) => {
            try {
              alert(`${error.response.data.erro}`);
            } catch (err) {
              alert("Something was wrong. Erro:", err);
            }
          });
      });
    },

    updateCard() {
      this.data = [];
    },
  },

  mounted() {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      alert("You need be loged to access the page.");
      window.location.href = "#/";
    }

    this.getCard(accessToken);
  },
});
</script>

<style>
body {
  font-family: "Roboto", sans-serif;
  margin: 0;
}

.contLabel {
  color: #fefefe;
  font-weight: bolder;
  font-family: "Roboto", sans-serif;
}

.checkoutCont {
  width: 400px;
  color: #fefefe;
  font-weight: bolder;
  margin-top: 5px;
}

.add {
  margin-top: 5px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #fefefe;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 5px;
  color: #fefefe;
  font-weight: bolder;
  font-size: 16px;
  transition: all 300ms ease;
  font-family: "Roboto", sans-serif;
}

.add:hover {
  background-color: #fefefe;
  color: black;
  cursor: pointer;
  border-radius: 5px;
  transition: all 300ms ease;
}

.ul-card {
  padding-left: 0;
}

.li-card {
  list-style: none;
}

.credit-card {
  background-color: #333;
  color: #fff;
  padding: 15px;
  border-radius: 10px;
  width: 300px;
  margin-bottom: 10px;
}

.card-brand {
  font-size: 18px;
  margin-bottom: 10px;
}

.card-number {
  font-size: 20px;
  letter-spacing: 2px;
}
</style>
