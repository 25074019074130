<template>
  <div>
    <BackgroundComp />

    <div class="containerHome">
      <div class="content">
        <p class="bloguehome">About</p>
        <h1 class="homeh1">Introducing <br />Docs</h1>
        <h2 class="homeh2">
          Okay, this isn't like a regular doc, the tool is much simpler than
          that, are you a marketer? Are you a financier? Are you a quantum
          computer engineer? Are you none of those things? CharTwo is definitely
          your place.
        </h2>
        <div class="aboutContainerhome">
          <a class="aboutContent" :href="stripeLink"
            >Try Sentinela 3.0 <i class="fas fa-arrow-right arrow"></i>
          </a>
          <a class="aboutContent">Illustration: Ruby Chen</a>
        </div>
      </div>
      <div>
        <img
          src="../assets/chartwo-plus.png"
          alt="chartwo"
          class="chartwohome"
        />

        <img
          src="../assets/chartwo-plus.png"
          alt="chartwo"
          class="chartwohome"
        />
      </div>
    </div>
    <div class="containerHome">
      <div class="content">
        <p class="bloguehome">Model</p>
        <h1 class="homeh1">Introducing <br />Models Sentinela</h1>
        <h2 class="homeh2">
          Our model has advanced image recognition related to graphics, some of
          the best models were built just for users like you, something so
          simple but so special that it makes all the difference when investing
          your money. New models coming soon
        </h2>
        <div class="aboutContainerhome">
          <a class="aboutContent" :href="stripeLink"
            >Try Sentinela 3.0 <i class="fas fa-arrow-right arrow"></i>
          </a>
          <a
            class="aboutContent"
            href="https://marteeparaosfracos.blogspot.com/2014/05/a-arte-conceitual-de-x-men-dias-de-um.html"
            >Who made these images</a
          >
        </div>
      </div>
      <div>
        <img
          src="../assets/SENTINELA-2.jpg"
          alt="chartwo"
          class="chartwohome"
        />
        <img
          src="../assets/chartwo-plus.png"
          alt="chartwo"
          class="chartwohome"
        />
      </div>
    </div>
    <div class="containerHome">
      <div class="content">
        <p class="bloguehome">Binance</p>
        <h1 class="homeh1">Introducing <br />Binance</h1>
        <h2 class="homeh2">
          Okay, let's admit that we have a soft spot for Binance, after all we
          wouldn't be much without the largest cryptocurrency broker in the
          world, although it's pretty obvious our tool is extraordinary if it
          matches its needs with those of Binance.
        </h2>
        <div class="aboutContainerhome">
          <a class="aboutContent" :href="stripeLink"
            >Try Sentinela 3.0 <i class="fas fa-arrow-right arrow"></i>
          </a>
          <a class="aboutContent">Illustration: Ruby Chen</a>
        </div>
      </div>
      <div>
        <img
          src="../assets/chartwo-plus.png"
          alt="chartwo"
          class="chartwohome"
        />
        <img
          src="../assets/chartwo-plus.png"
          alt="chartwo"
          class="chartwohome"
        />
      </div>
    </div>
    <div class="containerHome">
      <div class="content">
        <p class="bloguehome">Sentinela 2.0</p>
        <h1 class="homeh1">Introducing <br />Sentinela 2.0</h1>
        <h2 class="homeh2">
          Sentinela 2.0 has an advanced machine learning engine where more than
          10,000 images were used for training, which means it can accurately
          select each polygon on the candlestick chart, thus obtaining the best
          possible result for this type of model
        </h2>
        <div class="aboutContainerhome">
          <a class="aboutContent" :href="stripeLink"
            >Try Sentinela 3.0 <i class="fas fa-arrow-right arrow"></i>
          </a>
          <a
            class="aboutContent"
            href="https://marteeparaosfracos.blogspot.com/2014/05/a-arte-conceitual-de-x-men-dias-de-um.html"
            >Who made these images</a
          >
        </div>
      </div>
      <div>
        <img
          src="../assets/SENTINELA-3.jpg"
          alt="chartwo"
          class="chartwohome"
        />
        <img
          src="../assets/chartwo-plus.png"
          alt="chartwo"
          class="chartwohome"
        />
      </div>
    </div>
    <div class="containerHome">
      <div class="content">
        <p class="bloguehome">Coming soon</p>
        <h1 class="homeh1">
          Sentinelas and<br />
          Machine learning
        </h1>
        <h2 class="homeh2">
          Humans always seek to evolve with time, and as time goes by, we
          understand how difficult it is to build complex and useful things for
          our lives, CharTwo already has its own model of the future, and at
          this moment we count on you to be part of it all.
        </h2>
        <div class="aboutContainerhome">
          <a class="aboutContent" :href="stripeLink"
            >Try Sentinela 3.0 <i class="fas fa-arrow-right arrow"></i>
          </a>
          <a class="aboutContent">Illustration: Ruby Chen</a>
        </div>
      </div>
      <div>
        <img
          src="../assets/chartwo-plus.png"
          alt="chartwo"
          class="chartwohome"
        />
        <img
          src="../assets/chartwo-plus.png"
          alt="chartwo"
          class="chartwohome"
        />
      </div>
    </div>
    <div class="containerHome">
      <div class="content">
        <p class="bloguehome">Sentinela 3.0</p>
        <h1 class="homeh1">Introducing <br />Sentinela 3.0</h1>
        <h2 class="homeh2">
          Sentinela 2.0 is knocking on the door while 3.0 is climbing the
          stairs, don't be disappointed, you can guarantee your VIP space, we
          know how extraordinary this all is and how about you don't have to
          wait? The first 1,000 people will have access to the first Sentinel
          3.0 model before its scheduled release date, it's a lot of nonsense...
        </h2>
        <div class="aboutContainerhome">
          <a class="aboutContent" :href="stripeLink"
            >Try Sentinela 3.0 <i class="fas fa-arrow-right arrow"></i>
          </a>
          <a
            class="aboutContent"
            href="https://marteeparaosfracos.blogspot.com/2014/05/a-arte-conceitual-de-x-men-dias-de-um.html"
            >Who made these images</a
          >
        </div>
      </div>
      <div>
        <img
          src="../assets/SENTINELA-4.jpg"
          alt="chartwo"
          class="chartwohome"
        />
        <img
          src="../assets/chartwo-plus.png"
          alt="chartwo"
          class="chartwohome"
        />
      </div>
    </div>
    <FooterCompVue />
  </div>
</template>

<script>
import BackgroundComp from "@/components/BackgroundComp.vue";
import FooterCompVue from "@/components/FooterComp.vue";
const stripe_link = process.env.VUE_APP_LINK_STRIPE_SENT_PRE_SALE;

export default {
  data() {
    return {
      stripeLink: stripe_link,
    };
  },
  components: {
    FooterCompVue,
    BackgroundComp,
  },
  mounted() {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/buy-button.js";
    script.async = true;
    document.head.appendChild(script);
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import "~@fortawesome/fontawesome-free/css/all.min.css";

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
}

.containerHome {
  display: flex;
  padding: 50px;
  align-items: center;
  justify-content: space-between;
  background-color: #00000088;
  height: 60vh;
}

.containerHome:nth-child(odd) {
  display: flex;
  padding: 50px;
  align-items: center;
  justify-content: space-between;
  background-color: #00000088;
  height: 60vh;
  direction: rtl;
}

.content {
  color: #fefefe;
}

h1 {
  font-size: 48px;
}

h2 {
  width: 40vw;
  font-size: 22px;
  margin-top: -20px;
  margin-bottom: 40px;
}

.chartwohome {
  background-repeat: no-repeat;
  background-size: cover;
  width: 48vh;
  height: 48vh;
}

.containerHome:nth-of-type(odd) .chartwohome:nth-of-type(2) {
  margin-left: 70px;
}

.containerHome:nth-child(odd) .chartwohome:nth-child(2n) {
  transform: 90deg;
  position: relative;
  margin: 0;
}

.containerHome:nth-child(odd) .chartwohome:nth-child(4n + 2) {
  transform: 90deg;
  position: relative;
  margin-right: 70px;
}

.arrow {
  rotate: -45deg;
  position: relative;
}

.bloguehome {
  margin-bottom: -20px;
}

.aboutContainerhome {
  margin-top: 15px;
}

.aboutContent {
  margin-right: 10px;
  padding: 5px;
  color: #fefefe;
  text-decoration: none;
}

.aboutContent:nth-child(1) {
  border: 1px solid #fefefe;
  border-radius: 5px;
  transition: 300ms ease-out;
  color: #fefefe;
  text-decoration: none;
}

.aboutContent:nth-child(1):hover {
  border: 1px solid transparent;
  background-color: #fefefe;
  color: black;
  cursor: pointer;
  transition: 300ms ease-out;
}

.aboutContent:nth-child(2):hover {
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (min-width: 320px) and (max-width: 1700px) {
  .chartwohome:nth-child(2) {
    display: none;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .homeh1 {
    font-size: 18px;
  }

  .homeh2 {
    width: 70vw;
    font-size: 14px;
    margin-top: -5px;
    margin-bottom: 20px;
  }

  .bloguehome {
    margin-bottom: -5px;
    font-size: 12px;
  }

  .containerHome {
    display: flex;
    padding: 20px;
    flex-direction: column;
    background-color: #00000088;
    height: 65vh;
    position: relative;
  }

  .chartwohome:nth-child(1) {
    width: 70vw;
    height: auto;
    margin-top: 20px;
  }

  .aboutContainerhome {
    margin-top: 15px;
    font-size: 12px;
  }

  .content2 {
    font-size: 12px;
  }
}

.container2 {
  display: grid;
  align-items: center;
  justify-content: center;
}

.content2 {
  padding: 15px;
  text-align: center;
  text-decoration: none;
  color: #fefefe;
  margin-top: 60px;
}

.content2:hover {
  text-decoration: underline;
  cursor: pointer;
}

.line {
  border-bottom: 1px solid #fefefe;
}

.minicons {
  color: #fefefe;
  padding: 15px;
  font-size: 22px;
  margin: 0 15px 0 15px;
}

.container3 {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
