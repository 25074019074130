<script setup>
import { ref } from "vue";
import StripeComp from "./StripeComp.vue";

const open = ref(false);
</script>

<template>
  <div>
    <button @click="open = true" class="addCardNumber">
      Add a card number to your account <i class="fas fa-arrow-right arrow"></i>
    </button>

    <div v-if="open" class="modal-mask" @click="open = false">
      <div class="modal-wrapper">
        <div class="modal-container" onclick="event.stopPropagation();">
          <button class="modal-default-button" @click="open = false">
            <i class="fa-solid fa-xmark"></i>
          </button>
          <StripeComp />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.addCardNumber {
  background-color: transparent;
  padding: 12px;
  color: #fefefe;
  font-size: 15px;
  font-weight: bolder;
  margin-top: -15px;
  border: 1px solid #fefefe;
  border-radius: 5px;
  transition: all 300ms ease;
}

.addCardNumber:hover {
  cursor: pointer;
  color: black;
  background-color: #fefefe;
  transition: all 300ms ease;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 400px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #171717;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  height: auto;
}

.modal-default-button {
  float: right;
  background-color: transparent;
  position: fixed;
  top: 0;
  right: 0;
  border: none;
  color: #fefefe;
  font-size: 24px;
}

.modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  transition: all 300ms ease;
}
</style>
