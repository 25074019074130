<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";

import HomePage from "./pages/HomePage.vue";
import AboutPage from "./pages/AboutPage.vue";
import NotFound from "./pages/NotFound.vue";
import LoginPage from "./pages/LoginPage.vue";
import RegisterPage from "./pages/AccountCreator.vue";
import WorkspacePage from "./pages/WorkspacePage.vue";
import AccountConfirm from "./pages/AccountConfirm.vue";
import ForgotPassword from "./pages/ForgotPassword.vue";
import ChangePassword from "./pages/ChangePassword.vue";
import HeaderComp from "./components/HeaderComp.vue";
import ModelsApiPage from "./pages/ModelsApiPage.vue";

const routes = {
  "/": HomePage,
  "/about": AboutPage,
  "/account/login": LoginPage,
  "/account/create": RegisterPage,
  "/workspace": WorkspacePage,
  "/account/confirm": AccountConfirm,
  "/account/forgot/password": ForgotPassword,
  "/account/change/password": ChangePassword,
  "/models/api": ModelsApiPage,
};

const currentPage = computed(() => {
  const route = useRoute();

  return routes[route.path] || NotFound;
});
</script>

<template>
  <HeaderComp />
  <component :is="currentPage" />
</template>

<style>
body {
  overflow-x: hidden;
}

.header {
  background-color: #3f3f3f88;
  width: 100vw;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 50px;
  height: 50px;
  padding: 20px;
}

.links {
  padding: 20px;
}

.aboutContentUp:nth-child(1) {
  color: #fefefe;
  margin-right: 15px;
  text-decoration: none;
}

.aboutContentUp:nth-child(1):hover {
  text-decoration: underline;
  cursor: pointer;
}

.aboutContentUp:nth-child(2) {
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #fefefe;
  border-radius: 5px;
  transition: 300ms ease-out;
  color: #fefefe;
  text-decoration: none;
}

.aboutContentUp:nth-child(2):hover {
  border: 1px solid transparent;
  background-color: #fefefe;
  color: black;
  cursor: pointer;
  transition: 300ms ease-out;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .aboutContentUp {
    font-size: 14px;
  }
}
</style>
