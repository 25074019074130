<template>
  <div>
    <BackgroundComp />
    <div v-if="loading" class="loading-square">
      <LoadingComp />
    </div>
    <div class="container">
      <div class="login-page">
        <div class="form">
          <form class="login-form">
            <div class="login">
              <h1 class="h1Form">Check your email</h1>
              <img src="../assets/icon.png" alt="chartwo" class="logoForm" />
            </div>

            <input type="text" placeholder="number" v-model="number" />

            <div class="timerContainer">
              You have {{ timer }} seconds to confirm your email
            </div>

            <button @click="confirmNumber">Submit</button>

            <p class="message">
              Have you already check your email?
              <a href="#/account/login">Login</a>
            </p>
          </form>
        </div>
      </div>
    </div>
    <FooterCompVue />
  </div>
</template>

<script>
import BackgroundComp from "@/components/BackgroundComp.vue";
import FooterCompVue from "@/components/FooterComp.vue";
const apiUrl = process.env.VUE_APP_API_URL;
import { useRoute } from "vue-router";
import axios from "axios";
import LoadingComp from "@/components/LoadingComp.vue";

export default {
  data() {
    return {
      number: "",
      token: "",
      timer: 60,
      loading: false,
    };
  },

  mounted() {
    const route = useRoute();

    this.token = route.query.hash;
  },

  components: {
    FooterCompVue,
    BackgroundComp,
    LoadingComp,
  },

  methods: {
    confirmNumber() {
      this.loading = true;

      axios
        .post(
          `${apiUrl}/api/account/confirm`,
          {
            num: this.number,
            hash: this.token,
          },
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          this.loading = false;
          alert(response.data.message);
          window.location.href = "#/account/login";
          console.log(response);
        })
        .catch((error) => {
          this.loading = false;
          alert(`${error.response.data.erro}`);
          console.log(error);
        });
    },

    startTimer() {
      this.timerInterval = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          this.stopTimer();
        }
      }, 1000);
    },

    stopTimer() {
      clearInterval(this.timerInterval);
    },
  },

  watch: {
    number(newVal) {
      if (newVal !== "") {
        this.startTimer();
      } else {
        this.stopTimer();
        this.timer = 60;
      }
    },
  },

  created() {
    this.startTimer();
  },

  beforeUnmount() {
    this.stopTimer();
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import "~@fortawesome/fontawesome-free/css/all.min.css";

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
}

.loading-square {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0.9);
}

.container {
  display: flex;
  padding: 50px;
  align-items: center;
  justify-content: space-between;
  background-color: #00000088;
  height: 60vh;
}

.content {
  color: #fefefe;
}

h1 {
  font-size: 48px;
}

h2 {
  width: 40vw;
  font-size: 22px;
  margin-top: -20px;
  margin-bottom: 40px;
}

.arrow {
  rotate: -45deg;
  position: relative;
}

.container2 {
  display: grid;
  align-items: center;
  justify-content: center;
}

.timerContainer {
  background-color: #111111;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 10px;
  color: gray;
  font-weight: bolder;
}

.content2 {
  padding: 15px;
  text-align: center;
  text-decoration: none;
  color: #fefefe;
  margin-top: 60px;
}

.content2:hover {
  text-decoration: underline;
  cursor: pointer;
}

.line {
  border-bottom: 1px solid #fefefe;
}

.minicons {
  color: #fefefe;
  padding: 15px;
  font-size: 22px;
  margin: 0 15px 0 15px;
}

.container3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page {
  width: 60vw;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.form {
  position: relative;
  z-index: 1;
  background-color: #3f3f3f88;
  max-width: 60vw;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  border-radius: 10px;
}

.btnform {
  margin-bottom: 10px;
}

.login {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #070707;
  margin-top: -35px;
}

.form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #111111;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 10px;
  font-weight: bolder;
  color: gray;
}

.form button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #070707;
  width: 100%;
  border: 0;
  padding: 15px;
  color: gray;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  border-radius: 10px;
  transition: all ease-out 300ms;
  font-weight: bolder;
}

.form button:hover,
.form button:active,
.form button:focus {
  background: rgba(7, 7, 7, 0.897);
  transition: all ease-out 300ms;
}

.form .message {
  margin: 15px 0 0;
  color: gray;
  font-weight: bolder;
  font-size: 14px;
}

.form .message a {
  color: #000;
  text-decoration: none;
  font-weight: bolder;
  font-size: 14px;
  text-decoration: underline;
}

.form .register-form {
  display: none;
}

.logoForm {
  width: 70px;
  height: 70px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .content2 {
    font-size: 12px;
  }
  .login-page {
    width: 80vw;
  }
  .form {
    max-width: 80vw;
  }
  .h1Form {
    font-size: 28px;
  }
  .logoForm {
    width: 40px;
    height: 40px;
  }
}
</style>
