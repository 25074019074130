<template>
  <div>
    <BackgroundComp />

    <div class="containerHomeErro">
      <h1 class="pageError">404</h1>
      <h2 class="pageError">
        Sorry, I think you got lost, there's nothing here :/
      </h2>
    </div>
    <FooterCompVue />
  </div>
</template>

<script>
import BackgroundComp from "@/components/BackgroundComp.vue";
import FooterCompVue from "@/components/FooterComp.vue";

export default {
  components: {
    FooterCompVue,
    BackgroundComp,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import "~@fortawesome/fontawesome-free/css/all.min.css";

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
}

.containerHomeErro {
  display: flex;
  padding: 50px;
  align-items: center;
  justify-content: center;
  background-color: #00000088;
  height: 60vh;
  text-align: center;
  flex-direction: column;
}

.pageError {
  color: #fefefe;
  scale: 2;
  font-size: 62px;
}

.pageError:nth-child(2) {
  color: #fefefe;
  scale: 1;
  font-size: 28px;
}
</style>
