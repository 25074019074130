<template>
  <div
    class="header"
    v-if="currentPage !== '/workspace' && currentPage !== '/models/api'"
  >
    <img
      src="../assets/icon.png"
      alt="chartwo"
      class="logo"
      @click="handleClick"
    />
    <div class="links">
      <div v-if="currentPage !== '/workspace' && currentPage !== '/models/api'">
        <a class="aboutContentUp" href="#/account/login" v-if="loged">
          Log in <i class="fas fa-arrow-right arrow"></i>
        </a>

        <a
          class="aboutContentUp"
          href="#/workspace"
          style="border: 1px solid #fefefe; padding: 5px; border-radius: 5px"
        >
          Try CharTwo <i class="fas fa-arrow-right arrow"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../routes/Router";

export default {
  data() {
    return {
      currentPage: "",
      loged: true,
    };
  },

  methods: {
    handleClick(event) {
      event.preventDefault();
      event.stopPropagation();

      router.push("/");
    },

    hasAccessToken() {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken !== null) {
        this.loged = false;
      } else {
        this.loged = true;
      }
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.currentPage = to.fullPath;
        this.hasAccessToken();
      },
    },
  },

  mounted() {
    this.hasAccessToken();
  },
};
</script>

<style>
body {
  overflow-x: hidden;
}

.header {
  background-color: #3f3f3f88;
  width: 100vw;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 50px;
  height: 50px;
  padding: 20px;
}

.links {
  padding: 20px;
  display: flex;
}

.contentLinks:nth-child(1) {
  margin-right: 15px;
}

.aboutContentUp:nth-child(1) {
  color: #fefefe;
  margin-right: 15px;
  text-decoration: none;
}

.aboutContentUp:nth-child(1):hover {
  text-decoration: underline;
  cursor: pointer;
}

.aboutContentUp:nth-child(2) {
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #fefefe;
  border-radius: 5px;
  transition: 300ms ease-out;
  color: #fefefe;
  text-decoration: none;
}

.aboutContentUp:nth-child(2):hover {
  border: 1px solid transparent;
  background-color: #fefefe;
  color: black;
  cursor: pointer;
  transition: 300ms ease-out;
}

.share {
  scale: 1.4;
  border: 1px solid gray;
  padding: 7px;
  border-radius: 5px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .aboutContentUp {
    font-size: 14px;
  }
}
</style>
