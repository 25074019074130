// router.js
import { createRouter, createWebHashHistory } from "vue-router";
import HomePage from "../pages/HomePage.vue";
import AboutPage from "../pages/AboutPage.vue";
import NotFound from "../pages/NotFound.vue";
import LoginPage from "../pages/LoginPage.vue";
import RegisterPage from "../pages/AccountCreator.vue";
import WorkspacePage from "../pages/WorkspacePage.vue";
import AccountConfirm from "../pages/AccountConfirm.vue";
import ForgotPassword from "../pages/ForgotPassword.vue";
import ChangePassword from "../pages/ChangePassword.vue";
import ModelsApiPage from "../pages/ModelsApiPage.vue";

const routes = [
  { path: "/", component: HomePage },
  { path: "/about", component: AboutPage },
  { path: "/account/login", component: LoginPage },
  { path: "/account/create", component: RegisterPage },
  { path: "/workspace", component: WorkspacePage },
  { path: "/account/confirm", component: AccountConfirm },
  { path: "/account/forgot/password", component: ForgotPassword },
  { path: "/account/change/password", component: ChangePassword },
  { path: "/models/api", component: ModelsApiPage },
  { path: "/:path(.*)", component: NotFound },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
