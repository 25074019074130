<template>
  <div class="container2">
    <a class="content2" href="#/about">
      Before starting, we recommend taking a look at the project documentation,
      available by clicking here.
    </a>
    <span class="line"></span>
    <div class="container3">
      <a href="#/models/api"><i class="fas fa-chart-line minicons" /></a>
      <a href="#/models/api"><i class="fab fa-bitcoin minicons" /></a>
      <a href="#/models/api"><i class="fas fa-brain minicons" /></a>
    </div>
  </div>
</template>
