<template>
  <div class="header">
    <img
      src="../assets/icon.png"
      alt="chartwo"
      class="logo"
      @click="handleClick"
    />
    <div class="links">
      <div class="contentLinks">
        <a class="aboutContentUpApi" @click="exportImages">
          <i class="fa fa-gear share" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </div>
  <div>
    <BackgroundComp />

    <div class="containerHomeApiAPI">
      <div class="content">
        <p class="bloguehome">Shoot at any time</p>
        <h1 class="homeh1">CharTwo API</h1>
        <pre>
          <h2 class="homeh2modelsAPI">
curl -X POST -H "Content-Type: application/json" -d

'[
  {
   "token_id": "cts_12345", 
   "img_base64": "img_base64",
   "accuracy": "65"
  }
]' 

https://chartwo-server.onrender.com/chartwo/api/register/use/api
          </h2>
        </pre>
        <h2 class="homeh2modelsAPI">
          <button
            class="btnAPI"
            v-if="eyeOpen && tokenId.length === 0"
            @click="generateToken"
          >
            Generate token
          </button>

          <div class="tokenId">
            <label v-if="eyeOpen && tokenId.length !== 0">TokenID</label>
            <div style="display: flex">
              <input
                v-if="eyeOpen"
                v-model="tokenId"
                class="inputTokenId"
                readonly
              />
              <i
                class="fa fa-trash deleteToken"
                v-if="eyeOpen && tokenId.length !== 0"
                @click="deleteToken"
              ></i>
            </div>

            <button class="eyeAPI" @click="toggleEye">
              <i :class="eyeIconClass"></i>
            </button>
            <div class="messageAPI">
              This token is used to authenticate access to the CharTwo API,
              <span class="ALERT">NEVER</span> share it with anyone.When
              deleting a token, remember that this action is irreversible. All
              content and associations linked to the token will be lost
              permanently. Back up important information before proceeding.
            </div>
          </div>
        </h2>
        <div class="aboutcontainerHomeApi">
          <PopupComp />
          <button @click="toggleChart" class="toggleChart">
            <i class="fas fa-exchange-alt"></i>
          </button>
        </div>
      </div>
      <div class="imgsAPI">
        <div style="display: flex">
          <img
            src="../assets/chartwo-plus.png"
            alt="chartwo"
            class="chartwohomeAPI"
          />

          <img
            src="../assets/chartwo-plus.png"
            alt="chartwo"
            class="chartwohomeAPI"
          />
        </div>
        <div class="ChartComp">
          <div v-if="showReqsChart">
            <ChartReqsComp />
          </div>
          <div v-else>
            <ChartUseComp />
          </div>
        </div>
      </div>
    </div>

    <FooterCompVue />
  </div>
</template>

<script>
import BackgroundComp from "@/components/BackgroundComp.vue";
import ChartReqsComp from "@/components/ChartReqsComp.vue";
import ChartUseComp from "@/components/ChartUseComp.vue";
import FooterCompVue from "@/components/FooterComp.vue";
import PopupComp from "@/components/PopupComp.vue";
import router from "@/routes/Router";
import axios from "axios";

const stripe_link = process.env.VUE_APP_LINK_STRIPE_SENT_PRE_SALE;
const apiUrl = process.env.VUE_APP_API_URL;

export default {
  data() {
    return {
      stripeLink: stripe_link,
      isModalVisible: false,
      eyeOpen: false,
      tokenId: "",
      showReqsChart: true,
    };
  },

  components: {
    FooterCompVue,
    BackgroundComp,
    PopupComp,
    ChartReqsComp,
    ChartUseComp,
  },

  computed: {
    eyeIconClass() {
      return this.eyeOpen ? "fas fa-eye" : "fas fa-eye-slash";
    },
  },

  methods: {
    handleClick() {
      router.push("/");
    },

    toggleEye() {
      this.eyeOpen = !this.eyeOpen;
    },

    getToken(accessToken) {
      if (accessToken !== null) {
        axios
          .get(`${apiUrl}/api/get/tokenid/${accessToken}`, {
            withCredentials: true,
          })
          .then((response) => {
            this.tokenId = response.data.tokenId;
          })
          .catch((error) => {
            alert(`${error}`);
          });
      }
    },

    generateToken() {
      const accessToken = localStorage.getItem("accessToken");
      axios
        .post(
          `${apiUrl}/api/create/tokenid`,
          {
            userId: accessToken,
          },
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          alert(response.data.message);
          this.getToken(accessToken);
        })
        .catch((error) => {
          alert(`${error}`);
        });
    },

    deleteToken() {
      const accessToken = localStorage.getItem("accessToken");

      axios
        .delete(`${apiUrl}/api/delete/tokenid`, {
          withCredentials: true,
          params: {
            userId: accessToken,
          },
        })
        .then((response) => {
          alert(response.data.message);
          this.getToken(accessToken);
        })
        .catch((error) => {
          alert(error);
        });
    },

    toggleChart() {
      this.showReqsChart = !this.showReqsChart;
    },
  },

  mounted() {
    // const script = document.createElement("script");
    // script.src = "https://js.stripe.com/v3/buy-button.js";
    // script.async = true;
    // document.head.appendChild(script);

    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      alert("You need be loged to access the page.");
      window.location.href = "#/";
    }

    this.getToken(accessToken);
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import "~@fortawesome/fontawesome-free/css/all.min.css";

body {
  font-family: "Roboto", sans-serif;
  margin: 0;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #4a4a4a;
  }

  &::-webkit-scrollbar-track {
    background-color: #282828;
  }
}

.containerHomeApiAPI {
  display: flex;
  padding: 50px;
  justify-content: space-between;
  background-color: #00000088;
  height: auto;
}

.content {
  color: #fefefe;
}

h1 {
  font-size: 48px;
}

h2 {
  width: 40vw;
  font-size: 22px;
  margin-top: -20px;
  margin-bottom: 40px;
}

.imgsAPI {
  height: auto;
}

.chartwohomeAPI:nth-child(1) {
  margin-right: 70px;
}

.chartwohomeAPI:nth-child(2) {
  rotate: 90deg;
}

.chartwohomeAPI {
  background-repeat: no-repeat;
  background-size: cover;
  width: 48vh;
  height: 48vh;
}

.arrow {
  rotate: -45deg;
  position: relative;
}

.bloguehome {
  margin-bottom: -20px;
}

.aboutcontainerHomeApi {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

.toggleChart {
  background-color: transparent;
  padding: 12px;
  color: #fefefe;
  font-size: 15px;
  font-weight: bolder;
  margin-top: -15px;
  border: 1px solid #fefefe;
  border-radius: 5px;
  transition: all 300ms ease;
}

.toggleChart:hover {
  cursor: pointer;
  color: black;
  background-color: #fefefe;
  transition: all 300ms ease;
}

.aboutContentUpApi {
  color: #fefefe;
}

.aboutContent {
  margin-right: 10px;
  padding: 5px;
  color: #fefefe;
  text-decoration: none;
}

.aboutContent:nth-child(1) {
  border: 1px solid #fefefe;
  border-radius: 5px;
  transition: 300ms ease-out;
  color: #fefefe;
  text-decoration: none;
}

.aboutContent:nth-child(1):hover {
  border: 1px solid transparent;
  background-color: #fefefe;
  color: black;
  cursor: pointer;
  transition: 300ms ease-out;
}

.aboutContent:nth-child(2):hover {
  text-decoration: underline;
  cursor: pointer;
}

.homeh2modelsAPI:nth-of-type(1) {
  background-color: #111111;
  padding: 20px;
  font-size: 18px;
  width: 35vw;
  height: 35vh;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #4a4a4a;
  }

  &::-webkit-scrollbar-track {
    background-color: #282828;
  }
}

.homeh2modelsAPI {
  background-color: #111111;
  padding: 20px;
  font-size: 18px;
  width: 35vw;
  height: 25vh;
  word-wrap: break-word;
  border-radius: 10px;
}

.btnAPI {
  background-color: transparent;
  padding: 12px;
  color: #fefefe;
  font-size: 16px;
  width: 100%;
  font-weight: bolder;
  border: 1px solid #fefefe;
  border-radius: 5px;
  transition: all 300ms ease;
  font-family: "Roboto", sans-serif;
}

.btnAPI:hover {
  background-color: #fefefe;
  cursor: pointer;
  color: black;
  transition: all 300ms ease;
  font-family: "Roboto", sans-serif;
}

.tokenId {
  margin-top: 15px;
}

.inputTokenId {
  outline: none;
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #fefefe;
  width: 100%;
  font-weight: bolder;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.eyeAPI {
  background-color: transparent;
  border: 1px solid #fefefe;
  padding: 5px;
  font-size: 16px;
  width: 100%;
  border-radius: 5px;
  color: #fefefe;
  cursor: pointer;
  transition: all 300ms ease;
}

.eyeAPI:hover {
  background-color: #fefefe;
  border: 1px solid #fefefe;
  padding: 5px;
  font-size: 16px;
  width: 100%;
  border-radius: 5px;
  color: black;
  cursor: pointer;
  transition: all 300ms ease;
}

.messageAPI {
  margin-top: 10px;
  padding: 5px;
}

.ALERT {
  color: tomato;
}

.deleteToken {
  margin-left: 5px;
  cursor: pointer;
  transition: all 300ms ease;
}

.deleteToken:hover {
  margin-left: 5px;
  cursor: pointer;
  color: tomato;
  transition: all 300ms ease;
}

.ChartComp {
  margin-top: 50px;
}

@media screen and (min-width: 320px) and (max-width: 1700px) {
  .chartwohomeAPI:nth-child(1) {
    margin-left: 50px;
  }
}

@media screen and (min-width: 950px) and (max-width: 1200px) {
  .chartwohomeAPI:nth-child(2) {
    display: none;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .homeh1 {
    font-size: 18px;
  }

  .homeh2 {
    width: 70vw;
    font-size: 14px;
    margin-top: -5px;
    margin-bottom: 20px;
  }

  .homeh2modelsAPI:nth-of-type(1) {
    width: 80vw;
  }

  .bloguehome {
    margin-bottom: -5px;
    font-size: 12px;
  }

  .containerHomeApiAPI {
    display: flex;
    padding: 20px;
    flex-direction: column;
    background-color: #00000088;
    height: auto;
    position: relative;
  }

  .chartwohomeAPI:nth-child(1) {
    margin-right: 0px;
    width: calc(100% - 5px);
    height: auto;
    margin-top: 20px;
    margin-left: 0px;
  }

  .chartwohomeAPI:nth-child(2) {
    display: none;
  }

  .aboutcontainerHomeApi {
    margin-top: 15px;
    font-size: 12px;
  }

  .content2 {
    font-size: 12px;
  }
}

.container2 {
  display: grid;
  align-items: center;
  justify-content: center;
}

.content2 {
  padding: 15px;
  text-align: center;
  text-decoration: none;
  color: #fefefe;
  margin-top: 60px;
}

.content2:hover {
  text-decoration: underline;
  cursor: pointer;
}

.line {
  border-bottom: 1px solid #fefefe;
}

.minicons {
  color: #fefefe;
  padding: 15px;
  font-size: 22px;
  margin: 0 15px 0 15px;
}

.container3 {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
