<template>
  <video
    autoplay
    loop
    muted
    :style="{
      width: 'auto',
      height: 'auto',
      position: 'fixed',
      top: '0',
      left: '0',
      zIndex: '-999',
    }"
  >
    <source src="../assets/background.mp4" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</template>
