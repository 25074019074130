<template>
  <a class="content2work" href="#/about">
    Before starting, we recommend taking a look at the project documentation,
    available by clicking here.
  </a>
  <span class="line"></span>
  <div class="container3">
    <a href="#/models/api"><i class="fas fa-chart-line miniconsWork" /></a>

    <a href="#/models/api"><i class="fab fa-bitcoin miniconsWork" /></a>

    <a href="#/models/api"><i class="fas fa-brain miniconsWork" /></a>
  </div>
</template>
