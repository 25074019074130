<template>
  <div>
    <BackgroundComp />

    <div class="containerHome">
      <div class="content">
        <p class="bloguehome">Blogue</p>
        <h1 class="homeh1">Introducing <br />CharTwo</h1>
        <h2 class="homeh2">
          We trained a model called CharTwo that interacts in a conventional.
          The Upload Format allows ChatIA to return the best precision for you,
          it can measure statistical data based on images, admit your mistakes
          and even indicate possible dangers. Check our official page to find
          out more.
        </h2>
        <div class="aboutContainerhome">
          <a class="aboutContent" href="#/workspace"
            >Try CharTwo <i class="fas fa-arrow-right arrow"></i>
          </a>
          <a class="aboutContent" href="#/about">Read about CharTwo Plus</a>
        </div>
      </div>
      <div style="display: flex">
        <img src="../assets/chartwo.png" alt="chartwo" class="chartwohomes" />
        <img src="../assets/chartwo.png" alt="chartwo" class="chartwohomes" />
      </div>
    </div>
    <FooterCompVue />
  </div>
</template>

<script>
import BackgroundComp from "@/components/BackgroundComp.vue";
import FooterCompVue from "@/components/FooterComp.vue";

export default {
  components: {
    FooterCompVue,
    BackgroundComp,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import "~@fortawesome/fontawesome-free/css/all.min.css";

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
}

.containerHome {
  display: flex;
  padding: 50px;
  align-items: center;
  justify-content: space-between;
  background-color: #00000088;
  height: 60vh;
}

.content {
  color: #fefefe;
}

h1 {
  font-size: 48px;
}

h2 {
  width: 40vw;
  font-size: 22px;
  margin-top: -20px;
  margin-bottom: 40px;
}

.chartwohomes {
  background-repeat: no-repeat;
  background-size: cover;
  width: 48vh;
  height: 48vh;
}

.chartwohomes:nth-child(1) {
  margin-left: 20px;
}

.chartwohomes:nth-child(2) {
  rotate: 90deg;
  position: relative;
  margin-left: 50px;
}

.arrow {
  rotate: -45deg;
  position: relative;
}

.bloguehome {
  margin-bottom: -20px;
}

.aboutContainerhome {
  margin-top: 15px;
}

.aboutContent {
  margin-right: 10px;
  padding: 5px;
}

.aboutContent:nth-child(1) {
  border: 1px solid #fefefe;
  border-radius: 5px;
  transition: 300ms ease-out;
  color: #fefefe;
  text-decoration: none;
}

.aboutContent:nth-child(1):hover {
  border: 1px solid transparent;
  background-color: #fefefe;
  color: black;
  cursor: pointer;
  transition: 300ms ease-out;
}

.aboutContent:nth-child(2):hover {
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (min-width: 320px) and (max-width: 1200px) {
  .chartwohomes:nth-child(1) {
    width: 48vh;
    height: 48vh;
  }
  .chartwohomes:nth-child(2) {
    display: none;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .homeh1 {
    font-size: 18px;
  }

  .homeh2 {
    width: 70vw;
    font-size: 14px;
    margin-top: -5px;
    margin-bottom: 20px;
  }

  .bloguehome {
    margin-bottom: -5px;
    font-size: 12px;
  }

  .containerHome {
    display: flex;
    padding: 20px;
    flex-direction: column;
    background-color: #00000088;
    height: 65vh;
    position: relative;
  }

  .chartwohomes:nth-child(1) {
    width: 70vw;
    height: auto;
    margin-top: 20px;
    margin-left: 0;
  }

  .aboutContainerhome {
    margin-top: 15px;
    font-size: 12px;
  }

  .content2 {
    font-size: 12px;
  }
}

.container2 {
  display: grid;
  align-items: center;
  justify-content: center;
}

.content2 {
  padding: 15px;
  text-align: center;
  text-decoration: none;
  color: #fefefe;
  margin-top: 60px;
}

.content2:hover {
  text-decoration: underline;
  cursor: pointer;
}

.line {
  border-bottom: 1px solid #fefefe;
}

.minicons {
  color: #fefefe;
  padding: 15px;
  font-size: 22px;
  margin: 0 15px 0 15px;
}

.container3 {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
